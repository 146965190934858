import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchBlog, updateBlog } from "./api";

const EditBlog: React.FC = () => {
	const { id } = useParams<{ id: string }>();
	const [title, setTitle] = useState("");
	const [content, setContent] = useState("");
	const [isPublished, setIsPublished] = useState(false);

	useEffect(() => {
		const fetchBlogToEdit = async () => {
			const data = await fetchBlog(id!);
			setTitle(data.title);
			setContent(data.content);
			setIsPublished(data.isPublished);
		};

		fetchBlogToEdit();
	}, [id]);

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		await updateBlog(id!, { title, content, isPublished });
		window.location.href = `/blogs/${id}`;
	};

	return (
		<div>
			<h1>Edit Blog</h1>
			<form onSubmit={handleSubmit}>
				<input
					type="text"
					placeholder="Title"
					value={title}
					onChange={(e) => setTitle(e.target.value)}
				/>
				<textarea
					placeholder="Content"
					value={content}
					onChange={(e) => setContent(e.target.value)}
				></textarea>
				<label>
					<input
						type="checkbox"
						checked={isPublished}
						onChange={(e) => setIsPublished(e.target.checked)}
					/>
					Published
				</label>
				<button type="submit">Update Blog</button>
			</form>
		</div>
	);
};

export default EditBlog;
