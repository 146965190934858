import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import rehypeHighlight from "rehype-highlight";
import { fetchBlog } from "./api";
import Spinner from "../shared/Spinner";

const BlogDetail: React.FC = () => {
	const { id } = useParams<{ id: string }>();
	const [blog, setBlog] = useState<any>(null);

	useEffect(() => {
		const fetchSingleBlog = async () => {
			try {
				const data = await fetchBlog(id!);
				setBlog(data);
			} catch (error) {
				console.error("Error fetching blog:", error);
			}
		};

		fetchSingleBlog();
	}, [id]);

	if (!blog) {
		return <Spinner />;
	}

	return (
		<main className="pt-8 pb-16 lg:pt-16 lg:pb-24 bg-rose-400 bg-opacity-50 antialiased">
			<div className="px-4 mx-auto max-w-screen-xl">
				<div className="bg-white dark:bg-gray-900 rounded-lg shadow-lg p-8">
					<article className="w-full">
						<header className="mb-6 text-center">
							<address className="flex flex-col items-center mb-4 not-italic">
								<img
									className="w-20 h-20 rounded-full mb-2"
									src={blog.author?.avatar || "https://via.placeholder.com/150"}
									alt={blog.author?.username || "Author"}
								/>
								<div className="text-center">
									<a
										href="#"
										rel="author"
										className="text-xl font-bold text-gray-900 dark:text-white"
									>
										{blog.author?.username || "Anonymous"}
									</a>
									<p className="text-sm text-gray-500 dark:text-gray-400">
										{blog.authorTitle || "Contributor"}
									</p>
									<p className="text-sm text-gray-500 dark:text-gray-400">
										<time dateTime={blog.createdAt}>
											{new Date(blog.createdAt).toLocaleDateString()}
										</time>
									</p>
								</div>
							</address>
							<h1 className="text-4xl font-extrabold text-gray-900 dark:text-white">
								{blog.title}
							</h1>
						</header>

						<ReactMarkdown
							rehypePlugins={[rehypeHighlight]}
							className="prose dark:prose-invert text-center mx-auto"
						>
							{blog.content || ""}
						</ReactMarkdown>

						<footer className="mt-8 text-center">
							<a
								href={`/blogs/edit/${blog._id}`}
								className="text-primary-600 dark:text-primary-500 hover:underline"
							>
								Edit Blog
							</a>
						</footer>
					</article>
				</div>
			</div>
		</main>
	);
};

export default BlogDetail;
