import React, { createContext, ReactNode, useEffect, useState } from "react";
import { getLoggedInUser, login } from "./api";

interface AuthContextType {
	user: any;
	avatar: string;
	logout: () => void;
	loading: boolean;
	markItemAsRead: (itemID: string, isRead: boolean) => void;
	isLoggedIn: boolean;
	loginWithGoogle: () => void;
	loginWithDiscord: () => void;
	loginWithEmail: (email: string, password: string) => void;
	getReadItems: () => Promise<string[]>;
}

interface AuthProviderProps {
	children: ReactNode;
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

const oldFetch = window.fetch.bind(window);

window.fetch = async function (input: RequestInfo | URL, init?: RequestInit) {
	console.trace('Fetching', input)
	return oldFetch(input, init);
} 

export const AuthProvider = ({ children }: AuthProviderProps) => {
	const [user, setUser] = useState(null);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const fetchTokenAndProfile = async () => {
			const query = window.location.search;
			let token = new URLSearchParams(query).get("token");

			if (!token) {
				token = localStorage.getItem("token");
			} else {
				localStorage.setItem("token", token);
				window.history.replaceState({}, document.title, window.location.pathname);
			}

			if (token) {
				await fetchUserProfile(token);
			}
		};

		fetchTokenAndProfile();
	}, []);

	const fetchUserProfile = async (token: string) => {
		if (token) {
			setLoading(true);
			try {
				const response = await getLoggedInUser(token);

				if (!response.ok) {
					const errorData = await response.json();
					console.error('Failed to fetch user profile:', errorData);
					throw new Error(errorData.message || 'Failed to fetch user profile');
				}

				const data = await response.json();
				setUser(data);
			} catch (error) {
				console.error("Error fetching user profile:", error);
			} finally {
				setLoading(false);
			}
		}
	};

	const logout = () => {
		localStorage.removeItem("token");
		setUser(null);
	};

	const markItemAsRead = async (itemID: string, isRead: boolean) => {
		if (user) {
			const url = `${process.env.REACT_APP_API_BASE_URL}${isRead ? "/users/unmarkAsRead" : "/users/markAsRead"}`;
			await fetch(url, {
				method: "POST",
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`,
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ itemID }),
			});
		} else {
			const readItems = JSON.parse(localStorage.getItem("readItems") || "[]");
			if (isRead) {
				const newReadItems = readItems.filter((id: string) => id !== itemID);
				localStorage.setItem("readItems", JSON.stringify(newReadItems));
			} else {
				readItems.push(itemID);
				localStorage.setItem("readItems", JSON.stringify(readItems));
			}
		}
	};

	const getReadItems = async (): Promise<string[]> => {
		if (user) {
			const response = await getLoggedInUser();
			const { readItems } = await response.json();
			return readItems || [];
		}
		const localReadItems = JSON.parse(
			localStorage.getItem("readItems") || "[]"
		);
		return localReadItems;
	};

	const loginWithGoogle = () => {
		const url = `${process.env.REACT_APP_API_BASE_URL}/auth/google`;
		console.log('Redirecting to Google URI', url);
		window.location.href = url;
	};

	const loginWithDiscord = () => {
		const url = `${process.env.REACT_APP_API_BASE_URL}/auth/discord`;
		console.log('Redirecting to Discord URI', url);
		window.location.href = url;
	};

	const loginWithEmail = async (email: string, password: string) => {
		const response = await login(email, password);
		const data = await response.json();
		if (data.access_token) {
			localStorage.setItem("token", data.access_token);
			await fetchUserProfile(data.access_token);
		}
	};

	const isLoggedIn = !!user;

	if (loading) {
		return <div>Loading...</div>;
	}

	return (
		<AuthContext.Provider
			value={{
				user,
				logout,
				markItemAsRead,
				isLoggedIn,
				loginWithGoogle,
				loginWithDiscord,
				loginWithEmail,
				getReadItems,
				loading,
				avatar: (user as any)?.avatar || "https://via.placeholder.com/40", // TODO: Typings
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

export default AuthContext;
