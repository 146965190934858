type Props = {
	children: string | JSX.Element | JSX.Element[];
};

const OrnateContainer = ({ children }: Props) => {
	return (
		<div className="relative ornatediv p-2">
			<div className="innerornate">{children}</div>
		</div>
	);
};
export default OrnateContainer;
