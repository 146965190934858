import React, { useState } from "react";
import { createPortal } from "react-dom";
import Image from "./Image";

type LinkImagePopupProps = {
  imageSrc: string;
  title: string;
  buttonLabel: string;
};

const LinkImagePopup: React.FC<LinkImagePopupProps> = ({
  imageSrc,
  title,
  buttonLabel,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [position, setPosition] = useState<{ top: number; left: number }>({
	top: 0,
	left: 0,
  });

  const handleMouseEnter = (e: React.MouseEvent) => {
	const rect = e.currentTarget.getBoundingClientRect();
	const popupHeight = 200;
	setPosition({
	  top: rect.top + window.scrollY - popupHeight,
	  left: rect.left + window.scrollX + rect.width,
	});
	setIsVisible(true);
  };

  const handleMouseLeave = () => {
	setIsVisible(false);
  };

  return (
	<>
	  <a
		href="#"
		className="underline-squiggly text-blue-600"
		onMouseEnter={handleMouseEnter}
		onMouseLeave={handleMouseLeave}
	  >
		{buttonLabel}
	  </a>

	  {isVisible
		? createPortal(
			<div
			  role="tooltip"
			  className="absolute z-10 w-64 bg-white border-2 border-gray-800 rounded-lg shadow-lg dark:bg-gray-700"
			  style={{
				top: `${position.top}px`,
				left: `${position.left}px`,
			  }}
			>
			  <div className="px-3 py-2">
				<h3 className="font-semibold text-gray-900 dark:text-white">
				  {title}
				</h3>
				<Image src={imageSrc} alt={title} className="w-full h-auto mt-2" />
			  </div>
			</div>,
			document.body
		  )
		: <></>}
	</>
  );
};

export default LinkImagePopup;
