const formatDate = (date: Date) => {
	const options: Intl.DateTimeFormatOptions = {
		month: '2-digit',
		day: '2-digit',
		year: 'numeric',
		hour: '2-digit',
		minute: '2-digit',
		hour12: true
	};

	return new Intl.DateTimeFormat('en-US', options).format(date);
};

export default formatDate;
