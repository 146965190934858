import React, { useEffect, useRef, useState } from "react";
import GlitchedWriter, { wait } from "glitched-writer";
import "../../styles/chat.scss";
import formatDate from "../../utils/formatDate";
import { fetchMessages, Message, sendMessage } from "./api";

const Chat: React.FC = () => {
	const [loading, setLoading] = useState(false);
	const [messages, setMessages] = useState<Message[]>([]);
	const [input, setInput] = useState("");
	const logsRef = useRef<HTMLDivElement>(null);
	const glitchRef = useRef<HTMLDivElement>(null);
	const [writer, setWriter] = useState<GlitchedWriter | null>(null);

	useEffect(() => {
		const loadMessages = async () => {
			if (!loading) {
				setLoading(true);

				try {
					const fetchedMessages = await fetchMessages();

					for (const msg of fetchedMessages) {
						await wait(1000);
						await write(msg); 
					}

					setMessages(fetchedMessages);
					setLoading(false);
				} catch (error) {
					console.error("Error fetching messages:", error);
				}
			}  
		};

		loadMessages();
	}, []);

	const write = async (message: Message) => {
		let w = writer;
		if (!w) {
			const Writer = new GlitchedWriter(glitchRef.current!, { letterize: true });
			setWriter(Writer);
			w = Writer;
		}
		
		await w.write(message.text);
		logString(message);
	};

	const logString = (message: Message) => {
		setMessages((prev) => [...prev, message]);
		if (logsRef.current) {
			logsRef.current.scrollTop = logsRef.current.scrollHeight;
		}
	};

	const handleSend = async () => {
		if (input.trim() === "" || loading) return;

		try {
			const message = await sendMessage({ text: input });
			write(message);
			setInput("");
		} catch (error) {
			console.error("Error sending message:", error);
		}
	};

	const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		setInput(e.target.value);
	};

	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === "Enter") {
			handleSend();
		}
	};

	return (
		<div className="chatbox-container">
			<div className="log" id="logs" ref={logsRef}>
				{messages.map((msg, index) => (
					<p key={index}>
						<small>[{formatDate(new Date(msg.createdAt))}]:</small> {msg.sender?.username} {msg.text}
					</p>
				))}
			</div>

			<div className="text" id="glitch_this" ref={glitchRef}>
				Welcome
			</div>

			<div className="chat-input">
				<input
					className="input"
					placeholder="$ Type a message"
					value={input}
					onChange={handleInput}
					onKeyDown={handleKeyDown}
				/>
			</div>
		</div>
	);
};

export default Chat;
