import React, { useState, useEffect, useContext, useCallback } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { useResizeObserver } from '@wojtekmaj/react-hooks';
import Tippy from "@tippyjs/react";
import { IconX } from '@tabler/icons-react';
import { useSwipeable } from "react-swipeable";

import {
	// addComment as addCommentAPI,
	addFavorite,
	removeFavorite,
	addReadItem,
	removeReadItem,
	// getComments,
	fetchPreferences,
	addBookmark,
	removeBookmark,
	updateCurrentReading,
} from "./api";
import AuthContext from "../../contexts/AuthContext";
import Spinner from "../shared/Spinner";

import type { LibraryItem } from "@library/shared-types";

import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "tippy.js/dist/tippy.css";
import { useParams } from "react-router-dom";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

interface FileModalProps {
	isOpen: boolean;
	onRequestClose: () => void;
	item: LibraryItem;
}

const resizeObserverOptions = {};

const maxWidth = 800;

const FileModal: React.FC<FileModalProps> = ({
	isOpen,
	onRequestClose,
	item
}) => {
	const { isLoggedIn } = useContext(AuthContext);
	const { folder, page } = useParams();

	const [containerRef, setContainerRef] = useState<HTMLElement | null>(null);
	const [containerWidth, setContainerWidth] = useState<number>();

	// Adjust width on load
	useEffect(() => {
		if (containerRef) {
			const initialWidth = containerRef.getBoundingClientRect().width;
			setContainerWidth(initialWidth);
		}
	}, [containerRef]);

	// Adjust width on resize
	const onResize = useCallback<ResizeObserverCallback>((entries) => {
		const [entry] = entries;

		if (entry) {
			setContainerWidth(entry.contentRect.width);
		}
	}, []);

	useResizeObserver(containerRef, resizeObserverOptions, onResize);

	const isMobile = window.innerWidth <= 768;

	const handlers = useSwipeable({
		onSwipedLeft: () => {
			if (pageNumber < numPages!) {
				setPageNumber((prev) => prev + 1);
			}
		},
		onSwipedRight: () => {
			if (pageNumber > 1) {
				setPageNumber((prev) => prev - 1);
			}
		},
	});

	const [isFileLoading, setIsFileLoading] = useState(true);
	const [numPages, setNumPages] = useState<number | null>(null);
	const [pageNumber, setPageNumber] = useState<number>(page ? Number(page) : 1);

	/*
	const [comments, setComments] = useState<
		Array<{ pageIndex: number; commentText: string }>
	>([]);
	*/
	// const [newComment, setNewComment] = useState<string>("");
	const [isFavorite, setIsFavorite] = useState<boolean>(false);
	const [isRead, setIsRead] = useState<boolean>(false);
	const [bookmarks, setBookmarks] = useState<number[]>([]);
	const [blobFile, setBlobFile] = useState<Blob | null>(null);

	const fetchPreferencesAndUpdateState = useCallback(async () => {
		const preferences = await fetchPreferences();
		setIsFavorite(preferences.favorites?.includes(item._id));
		setIsRead(preferences.readItems?.includes(item._id));
		setBookmarks(preferences.bookmarks?.filter((b) => b.bookId === item._id).map((b) => b.pageNumber) || []);
	}, [item]);

	const fetchComments = useCallback(async () => {
		// const loadedComments = await getComments(item._id);
		// setComments(loadedComments);
	}, [item]);

	/*
	const handleAddComment = async () => {
		if (!isLoggedIn) return alert("You must be logged in to add comments.");
		if (!newComment.trim()) return;
		if (!item) throw Error("Missing book ID");

		const comment = {
			text: "",
			pageNumber,
			bookID: item._id,
			comment: newComment,
		};

		await addCommentAPI(comment);
		setComments((prev) => [
			...prev,
			{ pageIndex: pageNumber, commentText: newComment },
		]);
		setNewComment("");
	};
	*/

	const toggleFavorite = async () => {
		if (!item) throw Error("Missing book ID");
		if (isFavorite) {
			await removeFavorite(item._id);
			setIsFavorite(false);
		} else {
			await addFavorite(item._id);
			setIsFavorite(true);
		}
	};

	const toggleRead = async () => {
		if (!item) throw Error("Missing book ID");
		if (isRead) {
			await removeReadItem(item._id);
			setIsRead(false);
		} else {
			await addReadItem(item._id);
			setIsRead(true);
		}
	};

	const toggleBookmark = async () => {
		if (!item) throw Error("Missing book ID");
		if (bookmarks.includes(pageNumber)) {
			await removeBookmark(item._id, pageNumber);
			setBookmarks((prev) => prev.filter((p) => p !== pageNumber));
		} else {
			await addBookmark(item._id, pageNumber);
			setBookmarks((prev) => [...prev, pageNumber]);
		}
	};

	useEffect(() => {
		if (isOpen && item) {
			fetchPreferencesAndUpdateState();
			fetchComments();
		}
	}, [isOpen, item, fetchPreferencesAndUpdateState, fetchComments]);

	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			if (event.key === "ArrowRight" && pageNumber < numPages!) {
				setPageNumber((prevPage) => prevPage + 1);
			} else if (event.key === "ArrowLeft" && pageNumber > 1) {
				setPageNumber((prevPage) => prevPage - 1);
			}
		};

		if (isOpen) {
			window.addEventListener("keydown", handleKeyDown);
		}

		return () => {
			window.removeEventListener("keydown", handleKeyDown);
		};
	}, [isOpen, pageNumber, numPages]);

	useEffect(() => {
		// Close book if somehow gets logged out mid read
		if (!isLoggedIn) {
			onRequestClose();
		}
	}, [isLoggedIn]);

	useEffect(() => {
		if (isLoggedIn) {
			if (pageNumber && folder) {
				updateCurrentReading(folder, item._id, pageNumber);
			}

			if (pageNumber === numPages) {
				addReadItem(item._id);
			}
		}
	}, [pageNumber, isLoggedIn, folder]);

	useEffect(() => {
		let isMounted = true;

		if (isOpen && item.resourceLink) {
			setIsFileLoading(true);

			const fullPath = item.resourceLink.startsWith("http")
				? item.resourceLink
				: `${process.env.REACT_APP_API_BASE_URL}${item.resourceLink}`;

			fetch(fullPath)
				.then((res) => {
					if (!isMounted) return;

					if (!res.ok) {
						throw new Error("Failed to fetch the PDF file.");
					}

					const contentType = res.headers.get("Content-Type");
					if (contentType !== "application/pdf") {
						throw new Error(
							`Expected PDF but received: ${contentType || "unknown"}`
						);
					}
					return res.blob();
				})
				.then((blob) => {
					if (isMounted) {
						if (blob) {
							setBlobFile(blob);
						}
					}
				})
				.catch((err) => {
					console.error("Error fetching the PDF file:", err.message);
				})
				.finally(() => {
					if (isMounted) {
						setIsFileLoading(false);
					}
				});
	}

	return () => {
		isMounted = false;
	};
	}, [isOpen, item.resourceLink]);

	const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
		setNumPages(numPages);
		if (isFileLoading) setIsFileLoading(false);
	};

	const onDocumentLoadError = (error: Error) => {
		console.error("Error loading PDF:", error.message);
		if (isFileLoading) setIsFileLoading(false);
	};

	const handleModalClose = () => {
		setIsFileLoading(false);
		setNumPages(null);
		setPageNumber(1);
		// setComments([]);
		setIsFavorite(false);
		setIsRead(false);
		setBookmarks([]);
		if (onRequestClose) onRequestClose();
	};

	const memoizedFile = React.useMemo(() => {
		if (!blobFile) return null;
		return blobFile.type === "application/pdf" ? blobFile : null;
	}, [blobFile]);

	if (isFileLoading) {
		return <Spinner />;
	}

	if (!item?.resourceLink || !item._id) {
		return null;
	}

	 return (
    	<>
			<div
				{...handlers}
				className={`fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50 ${
					isOpen ? "" : "hidden"
				}`}
				onClick={(e) => {
					if (e.target === e.currentTarget) {
						handleModalClose();
					}
				}}
			>
			<div className="PDF__container relative w-[calc(100%-2rem)] max-w-5xl bg-white rounded-lg shadow dark:bg-gray-800 max-h-[95vh] overflow-auto">
			
			<div
				className="space-y-4 PDF__container__document flex flex-col items-center p-4 overflow-auto"
				ref={setContainerRef}
			>
				<div className="justify-end gap-4">
					<div className="flex items-center justify-between p-4 border-b dark:border-gray-600">
						<h3 className="text-xl font-semibold">
							{/* TODO: Store file names */}
							{(item as any).title || "File Viewer"}
						</h3>
				</div>

				<button onClick={handleModalClose} type="button" className="ms-2 btn-close" data-bs-dismiss="toast" aria-label="Close"><IconX /></button>

				<Tippy content={isFavorite ? "Unmark as Favorite" : "Mark as Favorite"}>
					<button
					onClick={toggleFavorite}
					className={`text-2xl ${
						isFavorite ? "text-yellow-500" : "text-gray-400"
					}`}
					>
					★
					</button>
				</Tippy>

				<Tippy content={isRead ? "Unmark as Read" : "Mark as Read"}>
					<button
						onClick={toggleRead}
						className={`text-xl ${
							isRead ? "text-blue-500 hover:text-blue-600" : "text-gray-400 hover:text-gray-500"
						}`}
					>
						📖
					</button>
				</Tippy>

				<Tippy
					content={bookmarks.includes(pageNumber) ? "Remove Bookmark" : "Add Bookmark"}
				>
					<button
					onClick={toggleBookmark}
					className={`text-2xl ${
						bookmarks.includes(pageNumber) ? "text-red-500" : "text-gray-400"
					}`}
					>
					🔖
					</button>
				</Tippy>
				</div>

				<Document
				file={memoizedFile}
				onLoadSuccess={onDocumentLoadSuccess}
				onLoadError={onDocumentLoadError}
				className="w-full"
				>
					<Page
						pageNumber={pageNumber}
						width={containerWidth ? Math.min(containerWidth, maxWidth) : maxWidth}
					/>
				</Document>

				{bookmarks.includes(pageNumber) && (
					<div
						className="absolute bg-red-500 w-12 h-48 top-12 right-6 rounded-md opacity-80 flex items-center justify-center text-white text-sm font-bold"
						style={{
							transform: "rotate(-10deg)",
							padding: "2px",
							boxSizing: "border-box",
						}}
					>
						<div
							className="absolute inset-0 rounded-md border border-white"
							style={{
								top: "2px",
								left: "2px",
								bottom: "2px",
								right: "2px",
								borderWidth: "1px",
							}}
						/>
							<span
							style={{
								transform: "rotate(10deg)",
								display: "block",
							}}
							>
							Marker
						</span>
					</div>
				)}
		
				{isMobile && <div className="flex justify-center gap-4">
				<button
					onClick={() => setPageNumber((prev) => Math.max(prev - 1, 1))}
					className="px-4 py-2 bg-blue-500 text-white rounded"
				>
					Previous
				</button>
				<span>
					Page {pageNumber} of {numPages || "?"}
				</span>
				<button
					onClick={() =>
					setPageNumber((prev) => Math.min(prev + 1, numPages || 1))
					}
					className="px-4 py-2 bg-blue-500 text-white rounded"
				>
					Next
				</button>
				</div>
				}
			</div>
			</div>
      	</div>
    </>
  );
};

export default FileModal;