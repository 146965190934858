const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export type Message = {
  text: string;
  createdAt: Date;
  sender: {
    username: string;
  }
};

export const fetchMessages = async (): Promise<Message[]> => {
	const response = await fetch(`${API_BASE_URL}/chat/messages`);
	if (!response.ok) {
		throw new Error('Failed to fetch messages');
	}

	return response.json();
};

export const sendMessage = async (message: { text: string }): Promise<Message> => {
	const response = await fetch(`${API_BASE_URL}/chat/send`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${localStorage.getItem('token')}`
		},
		body: JSON.stringify(message)
	});
	if (!response.ok) {
		throw new Error('Failed to send message');
	}

	return response.json();
};
