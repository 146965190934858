import React, { useState } from "react";
import ReactPlayer from "react-player/youtube";

const MusicPlayer: React.FC = () => {
	const playlistUrl = "https://www.youtube.com/playlist?list=PLscSujHjzKrcHRxAC1uJ6ObuXx259YYCW";
	const [playing, setPlaying] = useState(false);

	const togglePlay = () => setPlaying((prev) => !prev);

	return (
		<div className="fixed my-14 bottom-4 left-4 bg-gray-800 text-white rounded-lg shadow-lg p-4 w-72 z-50">
			<h1 className="text-lg font-bold mb-2">My Playlist</h1>
			<div className="youtube-music-player">
				<ReactPlayer
					url={playlistUrl}
					playing={playing}
					controls
					width="100%"
					height="80px"
				/>
				<div className="player-controls mt-4 flex space-x-2">
					<button
						onClick={togglePlay}
						className={`control-btn px-3 py-1 ${
							playing ? "bg-yellow-500 hover:bg-yellow-600" : "bg-blue-500 hover:bg-blue-600"
						} text-white rounded`}
					>
						{playing ? "Pause" : "Play"}
					</button>
					<button
						onClick={() => setPlaying(false)}
						className="control-btn px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600"
					>
						Stop
					</button>
				</div>
			</div>
		</div>
	);
};

export default MusicPlayer;
