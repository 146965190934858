import React from "react";
import { Link } from "react-router-dom";
import Image from './Image';
import Chat from "./chat/Chat";

const list = require.context("/public/img/badges", true);
const badges = list.keys().map((image: any) => list(image));

const Sidebar = ({ className }: { className?: string }) => {
	return (
		<div className={className}>
			<table
				className="linx"
				style={{ width: "100%", backgroundColor: "#FF7D8B" }}
			>
				<tbody>
					<tr>
						<td>
							<Link to="/" className="x">
								Home
							</Link>
						</td>
						<td>
							<Link to="/resources" className="x">
								Resources
							</Link>
						</td>
					</tr>
					<tr>
						<td>
							<Link to="/portfolio" className="x">
								Portfolio
							</Link>
						</td>
						<td>
							<Link to="/commissions" className="x">
								Commissions
							</Link>
						</td>
					</tr>
					<tr>
						<td>
							<Link to="/blogs" className="x">
								Blog
							</Link>
						</td>
					</tr>
				</tbody>
			</table>

			<center className="mt-4">
				<Image
					src="https://i1.glitter-graphics.org/pub/1143/1143971rjej46tko2.jpg"
					alt="Vintage Gif"
				/>
			</center>

			<div className="mt-4 p-2">
				<div className="flex flex-wrap">
					{badges.map((badge, index) => (
						<Image
							key={index}
							src={badge}
							alt={`Badge ${index + 1}`}
							className="h-5 w-auto"
							style={{ margin: 0 }}
						/>
					))}
				</div>
			</div>

			<div className="mt-4 flex-grow">
				<Chat />
			</div>
		</div>
	);
};

export default Sidebar;
