import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import AuthContext from "../../contexts/AuthContext";
import { LibraryItem } from "@library/shared-types";
import fetchWithBackoff from "../../utils/fetchWithBackoff";
import errorHandler from "../../utils/errorHandler";
import LibraryItemComponent from "./LibraryItemComponent";
import FileModal from "./FileModal";
import { fetchCurrentReading } from "./api";

const Library = () => {
  const navigate = useNavigate();
  const { folder, bookid: bookID } = useParams();
  const { isLoggedIn } = useContext(AuthContext);

  const [currentFolder, setCurrentFolder] = useState<LibraryItem | null>(null);
  const [navigationHistory, setNavigationHistory] = useState<LibraryItem[]>([]);
  const [library, setLibrary] = useState<LibraryItem[]>([]);
  const [pathSegments, setPathSegments] = useState<string[]>([]);
  const [item, setItem] = useState<LibraryItem | null>(null);
  const [readItems, setReadItems] = useState<string[]>([]);
  const { markItemAsRead, getReadItems } = useContext(AuthContext);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);

  const limit = 100;

  const fetchRootFolders = async () => {
    const data = await errorHandler(
      fetchWithBackoff<LibraryItem[]>(
        `${process.env.REACT_APP_API_BASE_URL}/library/root`
      ),
      setError
    );
    if (data) {
      setLibrary(data);
      setLoading(false);
    }
  };

  const fetchFolderContents = async (folder: string, page = 1) => {
    if (folder) {
      setLoading(true);
      const data = await errorHandler(
        fetchWithBackoff<{ data: LibraryItem[]; totalPages: number }>(
          `${process.env.REACT_APP_API_BASE_URL}/library/folder/${folder}?page=${page}&limit=${limit}`
        ),
        setError
      );
      if (data) {
        if (page === 1) {
          setLibrary(data.data);
        } else {
          setLibrary((prev) => [...prev, ...data.data]);
        }
        setTotalPages(data.totalPages);
      }
      setLoading(false);
    }
    else {
      console.warn('No folder specified, but tried to fetch folder contents');
    }
  };

  const loadReadItems = async () => {
    const items = await getReadItems();
    setReadItems(items);
  };

  const toggleReadStatus = (item: string) => {
    const isRead = readItems.includes(item);
    markItemAsRead(item, isRead);
    if (isRead) {
      setReadItems((prev) => prev.filter((id) => id !== item));
    } else {
      setReadItems((prev) => [...prev, item]);
    }
  };

  const navigateToRoot = () => {
    fetchRootFolders();
    setCurrentFolder(null);
    setPathSegments([]);
    navigate('/library');
  };

  const handleFolderClick = async (folder: LibraryItem) => {
    if (currentFolder) {
      setNavigationHistory((prev) => [...prev, currentFolder]);
    }

    setPage(1);
    await fetchFolderContents(folder._id, 1);
    setCurrentFolder(folder);
    navigate(`/library/${folder._id}`);
  };

  const handleBackClick = () => {
    if (navigationHistory.length > 0) {
      const lastFolder = navigationHistory[navigationHistory.length - 1];
      setNavigationHistory((prev) => prev.slice(0, -1));
      fetchFolderContents(lastFolder._id, 1);
      setCurrentFolder(lastFolder);
      navigate(`/library/${lastFolder._id}`);
    } else {
      navigateToRoot();
    }
  };

  const onFileClick = async ({ fileLink, item }: { fileLink: string; item: LibraryItem }) => {
    setItem(null);
    const updatedItem = await errorHandler(
      fetchWithBackoff<LibraryItem>(
        `${process.env.REACT_APP_API_BASE_URL}/library/file/${item._id}`
      ),
      setError
    );

    if (updatedItem) {
      // TODO: Load last read page for any book
      // navigate(`/library/${folder}/${item._id}`); // ! Don't use this because it interrupts the code
      window.history.replaceState(null, 'Library', `/library/${folder}/${item._id}`);
      setItem(updatedItem);
    }
  };

  const renderBreadcrumbs = () => {
    if (!currentFolder?.relativePath) return <><div>/</div></>;

    const newPathSegments = (currentFolder?.relativePath?.split('/') || currentFolder.relativePath.split('/'))
      .concat(currentFolder.name)
      .filter(Boolean);

    if (pathSegments.join('|') !== newPathSegments.join('|')) {
      setPathSegments(newPathSegments);
    }

    return (
      <nav className="text-blue-500 pixel-font">
        <a
          href="#"
          onClick={navigateToRoot}
          className="hover:underline"
        >
          Library
        </a>
        {pathSegments.map((segment, index) => {
          const segmentPath = pathSegments.slice(0, index + 1).join('/');
          return (
            <span key={segmentPath}>
              {" > "}
              <a
                href="#"
                onClick={() => navigateToPath(segmentPath)}
                className="hover:underline"
              >
                {segment}
              </a>
            </span>
          );
        })}
      </nav>
    );
  };

  const navigateToPath = async (path: string) => {
    const folderData = await errorHandler(
      fetchWithBackoff<LibraryItem>(
        `${process.env.REACT_APP_API_BASE_URL}/library/folder-by-path/${path}`
      ),
      setError
    );
    if (folderData) {
      setCurrentFolder(folderData);
      navigate(`/library/${folderData._id}`);
    }
  };

  useEffect(() => {
    const redirectToLastRead = async () => {
      try {
        const { folderId, bookId, pageNumber } = await fetchCurrentReading() || {};
    
        // If there's a book to redirect to, do that, otherwise, load the root library
        if (folderId && bookId && pageNumber && folderId !== 'undefined' && bookId !== 'undefined' && !window.location.href.includes(bookId)) {
          window.location.href = `/library/${folderId}/${bookId}/${pageNumber}`;
        }
        else {
          fetchRootFolders();
        }

        loadReadItems();
      } catch (error) {
        console.error('Error during redirection to last read:', error);
      }
    };

    if (isLoggedIn) {
      redirectToLastRead();
    }
  }, [isLoggedIn]);

  // Handle loading directly into a folder or a book
  useEffect(() => {
    const fetchCurrentFolderAndFile = async () => {
      setLoading(true);

      if (folder) {
        await fetchFolderContents(folder, 1);
      } else {
        await fetchRootFolders();
      }

      if (bookID) {
        const fileData = await errorHandler(
          fetchWithBackoff<LibraryItem>(
            `${process.env.REACT_APP_API_BASE_URL}/library/file/${bookID}`
          ),
          setError
        );

        if (fileData) {
          setItem(fileData);
        }
      }

      setLoading(false);
    };

    fetchCurrentFolderAndFile();
  }, [folder, bookID]);

  useEffect(() => {
    const checkContentHeight = () => {
      const scrollableHeight = document.documentElement.scrollHeight - window.innerHeight;

      if (scrollableHeight > 0 && !loading && page < totalPages) {
        setPage((prev) => prev + 1);
      }
    };

    checkContentHeight(); 

    const handleScroll = () => {
      const currentScroll = window.scrollY;
      const end = document.documentElement.scrollHeight - window.innerHeight - 100;
      if (currentScroll >= end && !loading && page < totalPages) {
        setPage((prev) => prev + 1);
      }
    };

    window.addEventListener("scroll", handleScroll);
  
    return () => window.removeEventListener("scroll", handleScroll);
  }, [loading, page, totalPages, library.length]);

  useEffect(() => {
    if (page > 1 && currentFolder) {
      fetchFolderContents(currentFolder._id, page);
    }
  }, [page, currentFolder]);

  useEffect(() => {
    const redirectToLastRead = async () => {
      try {
        const currentReading = await fetchCurrentReading();

        if (currentReading?.folderId && currentReading?.bookId && currentReading?.pageNumber) {
          const { folderId, bookId, pageNumber } = currentReading;

          const currentPath = location.pathname;

          if (currentPath.includes(bookId)) {
            return;
          }

          navigate(`/library/${folderId}/${bookId}/${pageNumber}`);
        } else {
          fetchRootFolders();
        }
      } catch (error) {
        console.error("Error during redirection to last read:", error);
        fetchRootFolders();
      }
    };

    if (isLoggedIn) {
      redirectToLastRead();
    }
  }, [isLoggedIn, location]);

  if (!isLoggedIn) {
    return <div>Please log in to access the library.</div>;
  }

  if (loading && page === 1) {
    return <div>Loading library data...</div>;
  }

  if (error) {
    return <div>Error loading library: {error}</div>;
  }

  return (
    <div className="p-6 bg-pastel-peach rounded-lg shadow-inner pixel-font overflow-y-auto max-h-screen pb-96">
      <div className="mb-4">
        {renderBreadcrumbs()}
      </div>

      {pathSegments.length ? (
        <button
          onClick={handleBackClick}
          className="mb-4 bg-pastel-pink text-white px-3 py-1 rounded pixel-font hover:shadow-inner transform active:translate-y-[2px]"
        >
          Back
        </button>
      ) : null}

      {(!loading && page < totalPages) ? (
        <button
          onClick={() => setPage((prev) => prev + 1)}
          className="mt-4 bg-pastel-blue text-white px-4 py-2 rounded pixel-font hover:shadow-inner transform active:translate-y-[2px]"
        >
          Load More
        </button>
      ) : null}

      <div className="bg-pastel-peach-dark rounded-lg p-4">
        {library?.map((item: LibraryItem, index: number) => (
          <LibraryItemComponent
            key={`${item._id}-${index + 1}`}
            item={item}
            toggleReadStatus={toggleReadStatus}
            readItems={readItems}
            onFolderClick={handleFolderClick}
            onFileClick={onFileClick}
            level={pathSegments.length}
            index={index}
          />
        ))}
      </div>

      {item?.type === "file" ? (
        <FileModal
          isOpen={!!item}
          onRequestClose={() => setItem(null)}
          item={item}
        />
      ) : null}

      {loading && <div>Loading more...</div>}
    </div>
  );
};

export default Library;
