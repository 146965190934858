import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import AuthContext from "../contexts/AuthContext";
import OrnateContainer from "./OrnateContainer";
import ShapeAroundImage from "./ShapeAroundImage";
import Image from './Image';

// TODO: Use self website links instead of google drive links
const recommendations = [
	{
		title: "Good Books",
		list: [
			{
				name: "No Bad Parts",
				link: "https://drive.google.com/file/d/1dQUMtNtGL4qqsvp20pTmc3OSIgLdFQoA/view?usp=drive_link",
			},
			{
				name: "Adult Children of Emotionally Immature Parents",
				link: "https://drive.google.com/file/d/1sWQWaeCtr4JLhemOJSYAK7qYXoibJFrB/view?usp=drive_link",
			},
			{
				name: "The Haunted Self",
				link: "https://drive.google.com/file/d/1jT6IMCIoXcI7dM9rAfvPbB1O1LaVRHJf/view?usp=drive_link",
			},
			{
				name: "The Body Never Lies",
				link: "https://drive.google.com/file/d/1zX8B0i8x-gdV4AAgMBUcUHlcuD4ztm6J/view?usp=drive_link",
			},
			{
				name: "The Body Keeps the Score",
				link: "https://drive.google.com/file/d/1Q0gWQHNrFNAGo2GPbNjqAPN_WmTLRu73/view?usp=drive_link",
			},
			{
				name: "The Emotional Incest Syndrome",
				link: "https://drive.google.com/file/d/1jt_LdmvqRv2LnKJg1VA-8QvGeJXVmmSv/view?usp=drive_link",
			},
			{
				name: "Facing Shame",
				link: "https://drive.google.com/file/d/1mDvxfImmuOxclkRoMM-MUrpwjbdl3bws/view?usp=drive_link",
			},
		],
	},
	{
		title: "Good Self Help",
		list: [
			{
				name: "Treating c-PTSD",
				link: "https://drive.google.com/file/d/1DwIGDQaB8St5wiLcUnYbJNMVwOiykEL5/view?usp=drive_link",
			},
			{
				name: "Your Personality and You Workbook",
				link: "https://drive.google.com/file/d/1k7qf1twDseoC_MuXp_okercs1tIv20Ax/view",
			},
			{
				name: "Therapist Workbooks",
				link: "https://www.therapistaid.com/therapy-worksheets/dbt/adults?page=1",
			},
		],
	},
	{
		title: "Memoirs",
		list: [
			{
				name: "What The Bones Know",
				link: "https://drive.google.com/file/d/1of82vky5hYLDSciBVqOEz9Xccpk_5Ly0/view",
			},
			{
				name: "First Person Plural",
				link: "https://drive.google.com/file/d/1I43S2GH65xACCGU3IHTTNU7h8nuAFomF/view?usp=drive_link",
			},
		],
	},
	{
		title: "Articles",
		list: [
			{
				name: "Theory of Structural Dissociation",
				link: "https://www.emdrgateway.com/news/2022/10/14/5jp5h4bmhw1ivas04dhcghn92yjebk",
			},
		],
	},
];

const ResourcesPage: React.FC = () => {
	const { isLoggedIn } = useContext(AuthContext);
	const [hovered, setHovered] = useState(false);

	const buttonStyles = `relative flex items-center text-center p-2 bg-gray-500 border-2 border-gray-700 text-white pixel-font ${
		isLoggedIn ? "hover:shadow-inner" : "opacity-50 cursor-not-allowed"
	}`;
	const popupStyles = `absolute z-10 p-4 w-64 text-sm text-gray-500 bg-white border-2 border-gray-800 rounded-lg shadow-lg bg-[url('/public/img/bg/plaidbg6.png')] pixel-font`;

	return (
		<div className="p-4">
			<div className="relative flex">
				{!isLoggedIn ? (
					<div
						className={buttonStyles}
						onMouseEnter={() => setHovered(true)}
						onMouseLeave={() => setHovered(false)}
					>
						<Image
							src="/img/misc/lock5.png"
							alt="Access Resources"
							className="w-10 h-10"
						/>
						<span className="ml-2">Login to Access Resources</span>
					</div>
				) : (
					<Link to="/library" className={buttonStyles}>
						<Image
							src="/img/misc/fileopen1.png"
							alt="Access Resources"
							className="w-10 h-10"
						/>
						<span className="ml-2">Access Resources</span>
					</Link>
				)}

				{hovered && !isLoggedIn && (
					<div className={popupStyles} style={{ top: "120%", left: 0 }}>
						Log in to access the library of resources (<i>7 GB +</i>) I have
						hosted on this website, ranging from political academia to abnormal
						psychology!
					</div>
				)}
			</div>

			<div className="mt-8 p-6 bg-pastel-pink border-2 border-pastel-peach-dark rounded-lg pixel-font">
				<h2 className="text-2xl font-bold mb-4">Learning Stuff</h2>
				<p>
					Hi, here you can find the most useful stuff about my website. I love
					reading studies and books, so you can find the entire library by
					visiting the button above. It requires an account, this is just to
					keep it at least slightly less accessible for you know what reasons.
				</p>
				<br />
				<p>
					<ShapeAroundImage
						src="/img/hobbies/kiki3.gif"
						alt="Kiki"
						className="m-4 p-2 float-right"
					/>
					I have a lot of mental health struggles so I'd like to dedicate the
					section below this to some of my personally highly recommended sources
					that you can access without creating an account here.
				</p>
			</div>

			<div className="grid xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
				{recommendations.map((rec) => (
					<OrnateContainer>
						<>
							<h4 className="text-lg font-bold">{rec.title}</h4>
							<ul className="mt-4">
								{rec.list.map((item) => (
									<li className="mt-1">
										<a
											href={item.link}
											target="_blank"
											rel="noopener noreferrer"
										>
											{item.name}
										</a>
									</li>
								))}
							</ul>
						</>
					</OrnateContainer>
				))}
			</div>
		</div>
	);
};

export default ResourcesPage;
