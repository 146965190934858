export const getLoggedInUser = async (token: string | null = localStorage.getItem("token")) => {
	if (!token) {
		return Promise.reject(new Error("No token provided"));
	}
	return fetch(
		`${process.env.REACT_APP_API_BASE_URL}/auth/@me`,
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	);
}

export const login = async (email: string, password: string) => {
	return fetch(`${process.env.REACT_APP_API_BASE_URL}/auth/login`,
		{
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ email, password }),
		}
	);
}