import React, { useContext, useState } from "react";
import AuthContext from "../contexts/AuthContext";
import Image from './Image';

const Login: React.FC = () => {
	const {
		avatar,
		loginWithGoogle,
		loginWithDiscord,
		loginWithEmail,
		isLoggedIn,
		user,
		logout,
		loading,
	} = useContext(AuthContext);

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [dropdownOpen, setDropdownOpen] = useState(false);

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault();
		loginWithEmail(email, password);
	};

	if (loading) {
		return <div>Loading...</div>;
	}

	return (
		<div>
			{!isLoggedIn ? (
				<div className="z-50">
					<button
						onClick={loginWithGoogle}
						className="bg-blue-500 text-white px-4 py-2 my-2 rounded"
					>
						Login with Google
					</button>
					<button
						onClick={loginWithDiscord}
						className="bg-indigo-500 text-white px-4 py-2 my-2 rounded ml-2"
					>
						Login with Discord
					</button>

					<form onSubmit={handleSubmit} className="mt-4">
						<input
							type="email"
							placeholder="Email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							className="border px-2 py-1 rounded"
						/>
						<input
							type="password"
							placeholder="Password"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							className="border px-2 py-1 rounded ml-2"
						/>
						<button
							type="submit"
							className="bg-green-500 text-white px-4 py-2 rounded ml-2"
						>
							Login
						</button>
					</form>
				</div>
			) : (
				<div className="relative">
					<div
						className="flex items-center cursor-pointer"
						onClick={() => setDropdownOpen(!dropdownOpen)}
					>
						<Image
							src={avatar}
							alt="avatar"
							className="w-10 h-10 rounded-full mr-2"
						/>
						<span>{user.username}</span>
						<svg
							className="w-5 h-5 ml-2"
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								d="M19 9l-7 7-7-7"
							/>
						</svg>
					</div>

					{dropdownOpen && (
						<div className="absolute right-0 mt-2 w-48 bg-white shadow-lg rounded-lg py-2 z-50">
							<button
								onClick={logout}
								className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
							>
								Logout
							</button>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default Login;
