import Image from './Image';

const ShapeAroundImage = ({
	src,
	alt,
	className = "",
	style = {},
}: {
	src: string;
	alt: string;
	className?: React.HTMLAttributes<HTMLImageElement>["className"];
	style?: React.HTMLAttributes<HTMLImageElement>["style"];
}) => (
	<Image
		src={src}
		alt={alt}
		className={className}
		style={{
			shapeOutside: `url("/public${src}")`,
			shapeMargin: "10px",
			height: "auto",
			...style,
		}}
	/>
);

export default ShapeAroundImage;
