import React from "react";
import { IconFileTypePdf, IconFolder } from '@tabler/icons-react';

import { LibraryItem } from "@library/shared-types";
import { darkenHex, lightenHex } from "../../utils/colour";

const pastelColors = ["#ff99ae", "#8df5fc", "#d4f400", "#ffc821"];

interface LibraryItemComponentProps {
	item: LibraryItem;
	toggleReadStatus: (item: string) => void;
	readItems: string[];
	onFolderClick: (item: LibraryItem) => void;
	onFileClick: ({ item, fileLink }: { item: LibraryItem; fileLink: string }) => void;
	level: number;
	index: number;
}

const LibraryItemComponent: React.FC<LibraryItemComponentProps> = ({
	item,
	toggleReadStatus,
	readItems,
	onFolderClick,
	onFileClick,
	level,
	index,
}) => {
	if (!item || !item._id) {
		return null;
	}

	const isRead = readItems?.includes(item._id);
	const resourceLink = item.resourceLink
		? `${process.env.REACT_APP_API_BASE_URL}${encodeURI(item.resourceLink)}`
		: "";

	const handleItemClick = () => {
		if (item.type === "folder") {
			onFolderClick(item);
		} else {
			onFileClick({ fileLink: resourceLink || "", item });
		}
	};

	const containerColor = pastelColors[index % pastelColors.length];

	return (
		<div
			key={item._id}
			className="p-4 mb-4 pixel-font hover:transform hover:translate-x-1 hover:translate-y-1"
			style={{
				backgroundColor: containerColor,
				marginLeft: `${level * 20}px`,
				borderLeft: `3px solid ${darkenHex(containerColor, 0.2)}`,
				borderBottom: `3px solid ${darkenHex(containerColor, 0.3)}`,
				borderRight: `3px solid ${darkenHex(containerColor, 0.2)}`,
				borderTop: `3px solid ${lightenHex(containerColor, 0.2)}`,
				transition: "transform 0.05s ease-out",
			}}
			onClick={handleItemClick}
			onMouseDown={(e) => {
				e.currentTarget.style.transform = "translate(2px, 2px)";
			}}
			onMouseUp={(e) => {
				e.currentTarget.style.transform = "translate(1px, 1px)";
			}}
		>
			<div className="flex justify-between items-center">
				{item.type === "folder" ? (
					<span className='flex mx-2 m-auto text-center justify-center items-center text-black'><IconFolder stroke={2} /><strong>{item.name}</strong></span>
				) : (
					<span className='flex mx-2 m-auto text-center justify-center items-center text-black'><IconFileTypePdf stroke={2} /><a className="underline">{item.name}</a></span>
				)}
				<div className="ml-4 flex items-center space-x-2">
					<button
						disabled
						onClick={(e) => {
							e.stopPropagation();
							toggleReadStatus(item._id);
						}}
						className="text-white px-2 py-1 pixel-font"
						style={{
							backgroundColor: "white",
							color: containerColor,
							borderBottom: `1px solid ${darkenHex("white", 0.2)}`,
							borderRight: `1px solid ${darkenHex("white", 0.2)}`,
							borderLeft: `1px solid ${lightenHex("white", 0.2)}`,
							borderTop: `1px solid ${lightenHex("white", 0.2)}`,
						}}
					>
						{isRead ? "Mark as Unread" : "Mark as Read"}
					</button>
					{item.type === "file" && (
						<a
							href={resourceLink}
							download
							className="ml-4 relative text-white px-2 py-1 pixel-font"
							style={{
								backgroundColor: "white",
								color: containerColor,
								borderLeft: `2px solid ${darkenHex("white", 0.15)}`,
								borderRight: `2px solid ${darkenHex("white", 0.15)}`,
								borderBottom: `2px solid ${darkenHex("white", 0.2)}`,
								borderTop: `2px solid ${lightenHex("white", 0.2)}`,
								boxShadow: "none",
								width: "fit-content",
								height: "fit-content",
							}}
							onClick={(e) => e.stopPropagation()}
							onMouseDown={(e) => {
								e.currentTarget.style.transform = "translate(2px, 2px)";
							}}
							onMouseUp={(e) => {
								e.currentTarget.style.transform = "translate(1px, 1px)";
							}}
						>
							<span className="relative">Download</span>
						</a>
					)}
				</div>
			</div>

			{item.contents?.length ? (
				<div className="mt-4">
					{item.contents.map((subItem: LibraryItem, subIndex: number) => (
						<LibraryItemComponent
							key={subItem._id}
							item={subItem}
							toggleReadStatus={toggleReadStatus}
							readItems={readItems}
							onFolderClick={onFolderClick}
							onFileClick={onFileClick}
							level={level + 1}
							index={subIndex}
						/>
					))}
				</div>
			) : null}
		</div>
	);
};

export default LibraryItemComponent;
