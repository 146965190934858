import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Analytics } from "@vercel/analytics/react"
import { SpeedInsights  } from "@vercel/speed-insights/react"

import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import Library from "./components/library/Library";
import Resources from "./components/Resources";
import Portfolio from "./components/Portfolio";
import Commissions from "./components/Commissions";
import Home from "./components/Home";
import { AuthProvider } from "./contexts/AuthContext";
import AddBlog from "./components/blog/AddBlog";
import BlogDetail from "./components/blog/BlogDetail";
import BlogList from "./components/blog/BlogList";
import EditBlog from "./components/blog/EditBlog";

function App() {
	return (
		<AuthProvider>
			<Router>
				<div
					className="flex flex-col h-screen"
					style={{
						cursor:
							"url(/img/cursors/ddr_arrow_01_by_kicked_in_teeth-dbicnxf.png), auto",
					}}
				>
					<Analytics />
					<SpeedInsights />

					<Navbar />
						<div className="flex h-screen border">
							<Sidebar className="hidden md:block w-96 bg-[#FF8F8F] text-[#3b4269] p-4 font-mono border-r-4 border-[#FF0000] min-h-screen" />
							<div className="flex-1 flex flex-col overflow-y-auto border">
							<Routes>
								<Route path="/" element={<Home />} />
								<Route path="/library" element={<Library />} />
								<Route path="/library/:folder" element={<Library />} />
								<Route path="/library/:folder/:bookid" element={<Library />} />
								<Route path="/library/:folder/:bookid/:page" element={<Library />} />
								<Route path="/resources" element={<Resources />} />
								<Route path="/portfolio" element={<Portfolio />} />
								<Route path="/commissions" element={<Commissions />} />
								<Route path="/blogs" element={<BlogList />} />
								<Route path="/blogs/new" element={<AddBlog />} />
								<Route path="/blogs/edit/:id" element={<EditBlog />} />
								<Route path="/blogs/:id" element={<BlogDetail />} />
							</Routes>
						</div>
					</div>
				</div>
			</Router>
		</AuthProvider>
	);
}

export default App;
