const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const token = () => localStorage.getItem("token");

export const fetchBlogs = async (): Promise<any[]> => {
	const response = await fetch(`${API_BASE_URL}/blogs`);

	return response.json();
};

export const fetchBlog = async (id: string): Promise<any> => {
	const response = await fetch(`${API_BASE_URL}/blogs/${id}`);

	return response.json();
};

export const createBlog = async (blog: { title: string; content: string; isPublished: boolean }): Promise<any> => {
	const response = await fetch(`${API_BASE_URL}/blogs`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token()}`
		},
		body: JSON.stringify(blog)
	});

	return response.json();
};

export const updateBlog = async (id: string, blog: Partial<{ title: string; content: string; isPublished: boolean }>): Promise<any> => {
	const response = await fetch(`${API_BASE_URL}/blogs/${id}`, {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token()}`
		},
		body: JSON.stringify(blog)
	});

	return response.json();
};

export const deleteBlog = async (id: string): Promise<void> => {
	await fetch(`${API_BASE_URL}/blogs/${id}`, { method: 'DELETE', headers: { Authorization: `Bearer ${token()}` } });
};
