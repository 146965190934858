export const lightenHex = (hex: string, percent: number) => {
	const num = parseInt(hex.slice(1), 16);
	const amt = Math.round(2.55 * percent * 100);
	const r = (num >> 16) + amt;
	const g = ((num >> 8) & 0x00ff) + amt;
	const b = (num & 0x0000ff) + amt;

	return `#${(
		0x1000000
    + (r < 255 ? (r < 1 ? 0 : r) : 255) * 0x10000
    + (g < 255 ? (g < 1 ? 0 : g) : 255) * 0x100
    + (b < 255 ? (b < 1 ? 0 : b) : 255)
	)
		.toString(16)
		.slice(1)}`;
};

export const darkenHex = (hex: string, percent: number) => {
	const num = parseInt(hex.slice(1), 16);
	const amt = Math.round(2.55 * percent * 100);
	const r = (num >> 16) - amt;
	const g = ((num >> 8) & 0x00ff) - amt;
	const b = (num & 0x0000ff) - amt;

	return `#${(
		0x1000000
    + (r < 255 ? (r < 1 ? 0 : r) : 255) * 0x10000
    + (g < 255 ? (g < 1 ? 0 : g) : 255) * 0x100
    + (b < 255 ? (b < 1 ? 0 : b) : 255)
	)
		.toString(16)
		.slice(1)}`;
};
