import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fetchBlogs, deleteBlog } from "./api";
import AuthContext from "../../contexts/AuthContext";

const BlogList: React.FC = () => {
	const { user } = useContext(AuthContext)
	const [blogs, setBlogs] = useState<any[]>([]);

	useEffect(() => {
		const fetchAllBlogs = async () => {
			const data = await fetchBlogs();
			setBlogs(data);
		};

		fetchAllBlogs();
	}, []);

	const handleDelete = async (id: string) => {
		if (window.confirm("Are you sure you want to delete this blog?")) {
			await deleteBlog(id);
			setBlogs((prev) => prev.filter((blog) => blog._id !== id));
		}
	};

	return (
		<section>
			<div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
				<div className="grid gap-8 lg:grid-cols-2">
					{blogs.map((blog) => (
						<article
							key={blog._id}
							className="p-6 bg-rose-400 bg-opacity-50 rounded-lg border border-gray-200 shadow-md"
						>
							<div className="flex justify-between items-center mb-5 text-gray-500">
								<span className="bg-primary-100 text-primary-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded dark:bg-primary-200 dark:text-primary-800">
									<svg
										className="mr-1 w-3 h-3"
										fill="currentColor"
										viewBox="0 0 20 20"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z"></path>
									</svg>
									Article
								</span>
								<span className="text-sm">{new Date(blog.createdAt).toLocaleDateString()}</span>
							</div>
							<h2 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
								<Link to={`/blogs/${blog._id}`}>{blog.title}</Link>
							</h2>
							<p className="mb-5 font-light text-gray-500 dark:text-gray-400">
								{blog.content.slice(0, 100)}...
							</p>
							<div className="flex justify-between items-center">
								<div className="flex items-center space-x-4">
									<img
										className="w-7 h-7 rounded-full"
										src={blog.author?.avatar || "https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/placeholder.png"}
										alt="Author avatar"
									/>
									<span className="font-medium dark:text-white">
										{blog.author?.username || "Unknown Author"}
									</span>
								</div>
								<div className="flex space-x-2">
									{user?.isAdmin ? (
										<button
											onClick={() => handleDelete(blog._id)}
											className="inline-flex items-center font-medium text-red-600 dark:text-red-500 hover:underline"
										>
											Delete
										</button>
									) : null}
									<Link
										to={`/blogs/${blog._id}`}
										className="inline-flex items-center font-medium text-primary-600 dark:text-primary-500 hover:underline"
									>
										Read more
										<svg
											className="ml-2 w-4 h-4"
											fill="currentColor"
											viewBox="0 0 20 20"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												fillRule="evenodd"
												d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
												clipRule="evenodd"
											></path>
										</svg>
									</Link>
								</div>
							</div>
						</article>
					))}
				</div>
				{user?.isAdmin ? (
					<div className="mt-6 text-center">
						<Link
							to="/blogs/new"
							className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
						>
							Add Blog
						</Link>
					</div>
				) : null}
			</div>
		</section>
	);
};

export default BlogList;
