/* https://www.brainyquote.com/authors/karl-marx-quotes */

const quotes = [
	{
		desc: "Let the ruling classes tremble at a communist revolution. The proletarians have nothing to lose but their chains. They have a world to win. Workingmen of all countries, unite!",
		id: "marx01",
	},
	{
		desc: "Social progress can be measured by the social position of the female sex.",
		id: "marx02",
	},
	{
		desc: "The theory of Communism may be summed up in one sentence: Abolish all private property.",
		id: "marx03",
	},
];

const Slider = () => {
	return (
		<div className="overflow-hidden shadow-sm p-4 marquee-container">
			<div className="marquee-content flex gap-8 items-center animate-marquee">
				{quotes.map((item) => {
					const { id, desc } = item;
					return (
						<div key={id} className="w-auto whitespace-nowrap">
							{desc}
						</div>
					);
				})}
				{quotes.map((item) => {
					const { id, desc } = item;
					return (
						<div key={`${id}-duplicate`} className="w-auto whitespace-nowrap">
							{desc}
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default Slider;
