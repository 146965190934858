import React from "react";

import getRoundedDecade from "../utils/age";
import OrnateContainer from "./OrnateContainer";
import LinkImagePopup from "./LinkImagePopup";
import ShapeAroundImage from "./ShapeAroundImage";
import Image from "./Image";
import Slider from "./Slider";
import MusicPlayer from "./Music";

const stampList = require.context("/public/img/stamps", true);
const stamps = stampList.keys().map((image: any) => stampList(image));

const AboutMe: React.FC = () => {
    return (
        <div className="about-me-container p-4 max-w-3xl mx-auto">
            <Slider />

            <div className="py-12 text-center">
                <h1 className="glitch" data-text="OKAMI">OKAMI</h1>
            </div>

            <h1 className="pixelated-cursive-header text-center">
                Welcome to <span className="highlight-blue">Me</span>
            </h1>

            <div className="about-me-text space-y-6">
                <div className="text-center">
                    <Image
                        src="/img/me/profile-cropped.png"
                        alt="Avatar"
                        className="avatar-left"
                    />
                </div>

                <p>
                    <span className="highlight-blue">name: </span>Okami
                </p>
                <p>
                    <span className="highlight-blue">age: </span>
                    {getRoundedDecade(1998)}
                </p>
                <p>
                    <span className="highlight-blue">currently doing: </span>building this
                    website!
                </p>
                <p>
                    <span className="highlight-blue">favourite foods: </span>pasta, steak
                </p>

                <div className="stamps-container grid grid-cols-3 gap-4">
                    {stamps.map((stamp, index) => (
                        <Image
                            key={index}
                            src={stamp}
                            alt={`Stamp ${index + 1}`}
                            className="stamp-image max-w-full h-auto"
                        />
                    ))}
                </div>

                <h1 className="pixelated-cursive-header text-center">
                    <span className="highlight-blue">Who Am I?</span>
                </h1>
                <p>
                    I'm known online as Okami. I'm an antifascist / anarchist silly furry.
                    DNI if you're trying to move me to the right!!! I draw art sometimes,
                    I code for a living, and I like playing games!
                </p>

                <h1 className="pixelated-cursive-header text-center">
                    <span className="highlight-blue">Why This Website?</span>
                </h1>
                <p>
                    <ShapeAroundImage
                        src="/img/hobbies/sakura-bouncing.gif"
                        alt="Cardcaptor Sakura"
                        className="p-2 float-none w-24 mx-auto md:avatar-left md:float-left"
                    />
                    Frankly, I mostly made this on impulse. I've been having very little
                    direction in my life and I wanted to build a home that feels like
                    "me", for whatever that's worth.
                    <ShapeAroundImage
                        src="/img/me/borboleta_by_cin_harurun-d5brpy3.gif"
                        alt="Borboleta"
                        className="p-2 float-none w-48 mx-auto w-24 md:avatar-left md:float-right"
                    />
                    I would like this to be a personal and interesting place where folks
                    can learn stuff. I struggle with an inconsistent and fragmented sense
                    of self from c-PTSD along with some other problems. I know how lonely
                    it is - so hopefully I can do some good onto the world with this.
                </p>
            </div>

            <div className="space-y-6 mt-8 flex">
                <OrnateContainer>
                    <h4 className="text-lg font-bold">Stuff I Like Doing</h4>
                    <ul className="mt-4 list-disc list-inside">
                        <li>getting involved in political discourse &amp; activism</li>
                        <li>drawing, painting, traditional art, digital art</li>
                        <li>software engineering (preferably backend stuff)</li>
                        <li>reading manga &amp; watching anime</li>
                        <li>
                            talking about cPTSD and related problems, providing resources
                            to others
                        </li>
                    </ul>
                </OrnateContainer>

                <OrnateContainer>
                    <h4 className="text-lg font-bold">Comfort Characters</h4>
                    <ul className="mt-4 list-disc list-inside">
                        <li>
                            <LinkImagePopup
                                imageSrc="/img/characters/aak.webp"
                                title="Aak (Arknights)"
                                buttonLabel="Aak"
                            />
                        </li>
                        <li>
                            <LinkImagePopup
                                imageSrc="/img/characters/ouma.webp"
                                title="Kokichi Ouma (Dangan Ronpa)"
                                buttonLabel="Kokichi Ouma"
                            />
                        </li>
                        <li>
                            <LinkImagePopup
                                imageSrc="/img/characters/shinso.webp"
                                title="Hitoshi Shinso (My Hero Academia)"
                                buttonLabel="Hitoshi Shinso"
                            />
                        </li>
                    </ul>
                </OrnateContainer>
            </div>
            <MusicPlayer />
        </div>
    );
};

export default AboutMe;
