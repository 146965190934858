export default function errorHandler<T>(
	promise: Promise<T>,
	// eslint-disable-next-line no-unused-vars
	setError: (error: string) => void
): Promise<T | null> {
	return promise.catch((err: Error) => {
		console.error('An error occurred:', err.message);
		setError(err.message);

		return null;
	});
}
