import React, { useState } from "react";
import { createBlog } from "./api";

const AddBlog: React.FC = () => {
	const [title, setTitle] = useState("");
	const [content, setContent] = useState("");
	const [isPublished, setIsPublished] = useState(false);

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		await createBlog({ title, content, isPublished });
		window.location.href = "/blogs";
	};

	return (
		<div>
			<h1>Add Blog</h1>
			<form onSubmit={handleSubmit}>
				<input
					type="text"
					placeholder="Title"
					value={title}
					onChange={(e) => setTitle(e.target.value)}
				/>
				<textarea
					placeholder="Content"
					value={content}
					onChange={(e) => setContent(e.target.value)}
				></textarea>
				<label>
					<input
						type="checkbox"
						checked={isPublished}
						onChange={(e) => setIsPublished(e.target.checked)}
					/>
					Published
				</label>
				<button type="submit">Add Blog</button>
			</form>
		</div>
	);
};

export default AddBlog;
