
export interface Highlight {
  text: string;
  pageNumber: number;
  bookID: string;
}

export interface Comment extends Highlight {
  comment: string;
}

export interface Bookmark {
  bookId: string;
  pageNumber: number;
}

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const token = () => localStorage.getItem("token");
const isLoggedIn = () => !!token;

const fetchWithAuth = async (url: string, options: RequestInit) => {
  if (!isLoggedIn()) {
    throw new Error("Unauthorized: User is not logged in.");
  }
  return fetch(url, options);
};

export const fetchPreferences = async (): Promise<{
  favorites: string[];
  readItems: string[];
  bookmarks: Bookmark[];
}> => {
	const response = await fetchWithAuth(`${API_BASE_URL}/preferences`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token()}`
		}
	});

	return response.json();
};

export const addFavorite = async (bookID: string): Promise<void> => {
	await fetchWithAuth(`${API_BASE_URL}/preferences/addFavorite`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token()}`
		},
		body: JSON.stringify({ bookID })
	});
};

export const removeFavorite = async (bookID: string): Promise<void> => {
	await fetchWithAuth(`${API_BASE_URL}/preferences/removeFavorite`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token()}`
		},
		body: JSON.stringify({ bookID })
	});
};

export const addReadItem = async (bookID: string): Promise<void> => {
	await fetchWithAuth(`${API_BASE_URL}/preferences/addReadItem`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token()}`
		},
		body: JSON.stringify({ bookID })
	});
};

export const removeReadItem = async (bookID: string): Promise<void> => {
	await fetchWithAuth(`${API_BASE_URL}/preferences/removeReadItem`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token()}`
		},
		body: JSON.stringify({ bookID })
	});
};

export const removeComment = async (bookID: string, pageIndex: number, commentText: string): Promise<void> => {
	await fetchWithAuth(`${API_BASE_URL}/preferences/removeComment`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token()}`
		},
		body: JSON.stringify({ bookID, pageIndex, commentText })
	});
};

export const getComments = async (bookID: string): Promise<Array<{ pageIndex: number; commentText: string }>> => {
	const response = await fetchWithAuth(`${API_BASE_URL}/preferences/comments?bookID=${bookID}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token()}`
		}
	});

	return response.json();
};

export const addHighlight = async (highlight: { text: string; pageNumber: number; bookID: string }) => {
	await fetchWithAuth(`${API_BASE_URL}/preferences/addHighlight`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token()}`
		},
		body: JSON.stringify(highlight)
	});
};

export const addComment = async (comment: { text: string; pageNumber: number; bookID: string; comment: string }) => {
	await fetchWithAuth(`${API_BASE_URL}/preferences/addComment`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token()}`
		},
		body: JSON.stringify(comment)
	});
};

export const addBookmark = async (bookID: string, pageNumber: number): Promise<void> => {
	await fetchWithAuth(`${API_BASE_URL}/preferences/addBookmark`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token()}`
		},
		body: JSON.stringify({ bookID, pageNumber })
	});
};

export const removeBookmark = async (bookID: string, pageNumber: number): Promise<void> => {
	await fetchWithAuth(`${API_BASE_URL}/preferences/removeBookmark`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token()}`
		},
		body: JSON.stringify({ bookID, pageNumber })
	});
};

export const updateCurrentReading = async (
  folderId: string,
  bookId: string,
  pageNumber: number
): Promise<void> => {
  await fetchWithAuth(`${API_BASE_URL}/preferences/updateCurrentReading`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token()}`,
    },
    body: JSON.stringify({ folderId, bookId, pageNumber }),
  });
};

export const fetchCurrentReading = async (): Promise<{
  folderId: string;
  bookId: string;
  pageNumber: number;
} | null> => {
  const response = await fetchWithAuth(`${API_BASE_URL}/preferences/currentReading`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token()}`,
    },
  });

  if (!response.ok) {
    console.error('Failed to fetch current reading:', await response.text());
    return null;
  }

  const data = await response.json();
  if (!data.folderId || !data.bookId || !data.pageNumber) {
    console.warn('Incomplete current reading data:', data);
    return null;
  }

  return data;
};

