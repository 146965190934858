import React from "react";

const Portfolio = () => {
	return (
		<div>
			<iframe
				src="https://portfolio.okami.codes"
				className="w-full h-screen border-0"
				title="Portfolio"
			>
			</iframe>
		</div>
	);
};

export default Portfolio;
